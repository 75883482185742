 <template>
    <div class="notifications-page table-page__thead--sticky">
        <notifications v-if="hideNotifications" @hide="hideNotifications= false" :value="editNotifications" @save="updateTable" />

        <PageHeaderTabs :tabs="tabs" :current-tab="currentTab">
            <template>
                <button class="btn-primary" @click.prevent="editNotifications = null; hideNotifications = true">Add rule</button>
            </template>
        </PageHeaderTabs>

        <v-table
            :id="'notifications'"
            :columns="columns"
            :panel="false"
            :tools="false"
            row-key="uuid"
            ref="notifications"
            endpoint="/api/notifications"
            sort-key="created_at"
            query-params
            no-cache
            :paginateValue="15"
            class="bg-white border-radius"
            :filter-params="filterParams"
            :custom-cols="getCustomCols()"
            :on-rows-loaded="onRowsLoaded"
            @row-click="rowClicked"
        >
            <template slot="header-cell" slot-scope="{col}">
                <span>
                    {{ col.title }}
                </span>
                <multiselect
                    v-if="col.key in filters && !hiddenFilters.includes(col.key)"
                    v-model="filters[col.key].value"
                    :options="filterOptions[col.key]"
                    :label="filters[col.key].label"
                    multiple
                    :track-by="filters[col.key].trackBy"
                >
                </multiselect>
            </template>
            <template slot="cell" slot-scope="{row, col}">
                <span v-if="col.key === 'organization_name'" :title="row.organization.name">
                    {{ row.organization.name }}
                </span>

                <span v-else-if="col.key === 'type'" :title="`${row.type || ''} ${row.details.details || ''}`">
                    {{ row.type || '' }} {{ row.details.details || '' }}
                </span>

                <span v-else-if="col.key === 'template_name'">
                    {{ row.template_name }}
                </span>

                <span v-else-if="col.key === 'recipients'">
                    {{ row.recipients.map(item => { return item.full_name }).join(', ') }}
                </span>

                <span v-else-if="col.key === 'created_by'" :title="row.created_by.name">
                    {{ row.created_by.name }}
                </span>

                <span v-else-if="col.key === 'is_active'" :key="`${col.key}_${row.id}`" class="flex flex__align-center justify-end mt-2" title="">
                    <checkbox
                        v-model="row[col.key]"
                        :disabled="!isAllowEditNotification(row.created_by.id)"
                        :id="row.id"
                        :checked="row[col.key]"
                        class="mr-2"
                        @change="isAllowEditNotification(row.created_by.id) ? toggleCheckbox(row) : false"
                    />

                    <button v-if="isAllowEditNotification(row.created_by.id)" class="ml-auto" @click="deleteRule(row)">
                        <svg-importer width="20" icon-name="icons/close" />
                    </button>
                </span>
                <span v-else></span>
            </template>
        </v-table>
    </div>
</template>

<script>
import Notifications from '~/pages/settings/Notifications.vue';
import PageHeaderTabs from '~/components/PageHeaderTabs';
import Multiselect from 'vue-multiselect'

import axios from 'axios';

export default {
    components: {
        Notifications,
        PageHeaderTabs,
        Multiselect,
    },

    watch: {
        filterParams() {
            this.getFilterOptions();
        },
    },

    data() {
        return {
            hideNotifications: false,
            editNotifications: null,
            organizations: [],
            templates: [],
            types: [],
            users: [],
            created_by: [],
            columns: [
                {
                    title: "Organization",
                    key: "organization_name",
                    toggled: true,
                    sortable: true,
                    width: "w-32",
                },
                {
                    title: "Trigger",
                    key: "type",
                    toggled: true,
                    sortable: true,
                    width: "w-32",
                },
                {
                    title: "Template",
                    key: "template_name",
                    toggled: true,
                    sortable: true,
                    width: "w-32",
                },
                {
                    title: "Recipients",
                    key: "recipients",
                    toggled: true,
                    sortable: false,
                    width: "w-32",
                },
                {
                    title: "Created by",
                    key: "created_by",
                    toggled: true,
                    sortable: true,
                    width: "w-32",
                },
                {
                    title: "Active",
                    key: "is_active",
                    toggled: true,
                    sortable: true,
                    width: "w-24",
                    preventClick: true,
                },
            ],
            tabs: {
                0: { value: 'Notifications', routeName: this.$route.name === 'admin.notifications' ? '/admin/notifications' : '/notifications' },
                1: { value: 'Canned Responses Templates', routeName: this.$route.name === 'admin.notifications' ? '/admin/notifications/canned-responses' : '/notifications/canned-responses' },
            },
            currentTab: 0,
            filters: {
                organization_name: {
                    name: 'organization_id',
                    value: null,
                    label: 'name',
                    trackBy: 'id',
                },
                type: {
                    name: 'types',
                    value: null,
                    label: 'label',
                    trackBy: 'label',
                },
                template_name: {
                    name: 'template_ids',
                    value: null,
                    label: 'name',
                    trackBy: 'id',
                },
                recipients: {
                    name: 'recipients',
                    value: null,
                    label: 'name',
                    trackBy: 'id',
                },
                created_by: {
                    name: 'created_by',
                    value: null,
                    label: 'name',
                    trackBy: 'id',
                },
            },
            filterOptions: {
                organization_name: [],
                type: [],
                template_name: [],
                recipients: [],
                created_by: [],
            },
        }
    },
    computed: {
        hiddenFilters() {
            return this.$route.name === 'admin.notifications' ? [] : ['created_by']
        },
        user() {
            return this.$store.getters['auth/user'];
        },
        isGodUser() {
            return this.$store.getters['auth/isGodUser'];
        },
        filterParams() {
            return  Object.values(this.filters).reduce((accum, filterValue) => {
                if (!filterValue.value) {
                    return accum;
                }

                accum[`filter[${filterValue.name}]`] = filterValue.value
                    .map(val => {
                        if (filterValue.name === 'created_by') {
                            return val.id;
                        }

                        return val.value || val.id || val.name;
                    })
                    .join(',');

                return accum;
            }, {});
        },
    },
    methods: {
        onRowsLoaded(rows) {
            rows.forEach(row => {
                row.is_active = Boolean(row.is_active);

                
                return row;
            });
        },
        getCustomCols() {
            return this.columns
                .filter(col => Object.keys(this.filters).includes(col.key))
                .map(col => col.key);
        },
        isAllowEditNotification(id) {
            return this.isGodUser || this.user.id === id;
        },
        async rowClicked(value) {
            this.editNotifications = value.data;
            this.hideNotifications = true;
        },
        async toggleCheckbox(row) {
            try {
                await axios.put(`/api/notifications/${row.id}`, {
                    organization_id: row.organization.id,
                    type: row.type,
                    details: row.details.details,
                    template_id: row.template_id,
                    site_ids: row.site_ids,
                    is_active: row.is_active,
                    mode: row.mode,
                });
    
                this.$snotify.success(`Notification rule updated!`);
            } catch (error) {
                this.$snotify.error(`Failed to update notification rule`);
            }

        },
        async deleteRule(row) {
            try {
                await axios.delete(`/api/notifications/${row.id}`);

                this.$snotify.success('Successfully')

                await this.updateTable();
            } catch (error) {
                this.$snotify.error(`Failed to delete notification rule`);
            }
        },
        async updateTable() {
            this.$refs['notifications'].table.fetchRows();
            this.getFilterOptions();
        },
        async getFilterOptions() {
            let filterQuery = Object.entries(this.filterParams)
                .map(([key, value]) => `${key}=${value}`)
                .join('&');

            const url = this.$apiUrl.notifications.filterOptions + (filterQuery.length ? `?${filterQuery}` : '');
            const { data } = await axios.get(url);

            this.filterOptions.organization_name = this.organizations
                .filter(organization => Object.keys(data.organization_id).includes(String(organization.id)));
            this.filterOptions.template_name = this.templates
                .filter(template => Object.keys(data.template_ids).includes(String(template.id)));
            this.filterOptions.type = this.types
                .filter(type => Object.keys(data.types).includes(type.value));
            this.filterOptions.created_by = this.created_by
                .filter(user => Object.keys(data.created_by).includes(String(user.id)));
            this.filterOptions.recipients = Object.entries(data.recipients)
                .map(([id, name]) => ({ id, name }));
        },
        async fetchOrganizations() {
            try {
                const { data: { data } } = await axios.get('/api/notifications/organizations');

                this.organizations = data;
            } catch(error) {
                this.organizations = [];
            }
        },

        async fetchTemplates() {
            try {
                const { data: { data } } = await axios.get(this.$apiUrl.cannedResponses.base);

                this.templates = data;
            } catch(error) {
                this.templates = [];
            }
        },

        async fetchXceptions() {
            try {
                const { data: { data } } = await axios.get('/api/notifications/types');

                this.types = data;
            } catch(error) {
                this.types = [];
            }
        },

        async fetchUsers() {
            try {
                const filterQuery = this.isGodUser ? '' : `filter[organization]=${this.user.organization.id}&`;
                const { data: { data } } = await axios.get(this.$apiUrl.users.list + `?${filterQuery}pageSize=10000`);
                this.users = data;
                this.created_by = data;
            } catch(_) {
                this.users = [];
                this.created_by = [];
            }
        },
    },
    async created() {
        await Promise.all([
            this.fetchXceptions(),
            this.fetchOrganizations(),
            this.fetchTemplates(),
            this.fetchUsers(),
        ]);

        this.getFilterOptions();
    },
}
</script>
<style lang="scss">
.button-wrapper {
    background: rgba(255, 255, 255, var(--bg-opacity));
}

.notifications-page {
    .table-wrapper {
        min-height: 400px;
        max-height: calc(100vh - 150px);
        overflow: auto;
    }
    .v-table {
        overflow: visible !important;
    }
}
</style>